<template>
  <div class="product">
    <b-container fluid="xl" class="">
      <Breadcrumb :items="breadcrumbItems"/>
    </b-container>
    <b-container fluid="xl" class="pt-3">
      <h1 class="mb-3">{{ product.name }}</h1>
    </b-container>
    <b-container fluid="xl" class="">
      <section class="py-3">
        <b-row>
          <b-col md="3" v-for="image in product.product_images" :key="image.filename">
            <b-img class="img-fluid img-thumbnail gallery-img" :src="image.image_url" :alt="image.filename"></b-img>
          </b-col>
        </b-row>
      </section>
      <section>
        <b-row>
          <b-col md="12">
            <div v-html="product.description" class="py-3"></div>
          </b-col>
        </b-row>
          <!-- <pre>{{ product }}</pre> -->
      </section>
    </b-container>
  </div>
</template>

<script>
  // if (typeof this.$route.params != 'undefined') {
  //   console.log(this.$route.params);
  // }



// @ is an alias to /src
import Breadcrumb from '@/components/Breadcrumb.vue'

export default {
  name: 'ProductView',
  data () {
    return {
      productName: this.$route.params.productName,
      product: {},
      breadcrumbItems: []
    }
  },
  watch: {
    '$route.params.productName': function (productName) {
      this.productName = productName;
      this.getProductContent()
    }
  },
  created () {
     this.getProductContent()
  },
  mounted() {
  },
  methods: {
    getProductContent() {
      if (typeof this.productName !== 'undefined') {
        this.$axios
          .get(this.$apiUrl+'/products/'+this.productName+'?token='+this.$apiToken)
          .then(response => {
            this.product = response.data.product;

            this.initBreadcrumb();

            
    

          }).catch(error => {
            console.log('There was an error:' + error.response)
          })      
      } else {
      
      }
    },
    initBreadcrumb () {
      this.breadcrumbItems = [{
                                text: 'Home',
                                href: '/'
                              },
                              {
                                text: 'Products',
                                href: '/products'
                              },
                              {
                                text: this.product.name,
                                active: true
                              }];
    }

  
  },
  components: {
    Breadcrumb,
  }
}
</script>


<style scoped>

</style>