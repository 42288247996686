<template>
  <div class="article">
    <b-container fluid="xl" class="">
      <Breadcrumb :items="breadcrumbItems"/>
    </b-container>
    <b-container fluid="xl" class="pt-3">
      <h1 class="mb-3">{{ article.title }}</h1>
    </b-container>
    <b-container fluid="xl" class="">
      <section>
        <b-row>
          <b-col md="12">
            <div v-html="article.body" class="py-3"></div>
          </b-col>
        </b-row>
          <!-- <pre>{{ article }}</pre> -->
      </section>
    </b-container>
  </div>
</template>

<script>
  // if (typeof this.$route.params != 'undefined') {
  //   console.log(this.$route.params);
  // }



// @ is an alias to /src
import Breadcrumb from '@/components/Breadcrumb.vue'

export default {
  name: 'ArticleView',
  data () {
    return {
      slug: this.$route.params.slug,
      article: {},
      breadcrumbItems: []
    }
  },
  watch: {
    '$route.params.slug': function (slug) {
      this.slug = slug;
      this.getArticleContent()
    }
  },
  created () {
     this.getArticleContent()
  },
  methods: {
    getArticleContent() {
      if (typeof this.slug !== 'undefined') {
        this.$axios
          .get(this.$apiUrl+'/articles/'+this.slug+'?token='+this.$apiToken)
          .then(response => {
            this.article = response.data.article;

            this.initBreadcrumb();

          }).catch(error => {
            console.log('There was an error:' + error.response)
          })      
      } else {
      
      }
    },
    initBreadcrumb () {
      this.breadcrumbItems = [{
                                text: 'Home',
                                href: '/'
                              },
                              {
                                text: 'Articles',
                                href: '/articles'
                              },
                              {
                                text: this.article.title,
                                active: true
                              }];
    }

  
  },
  components: {
    Breadcrumb,
  }
}
</script>


<style scoped>

</style>