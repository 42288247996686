<template>
  <div class="about">
    <b-container fluid="xl" class="pt-5">
      <h1 class="mb-3 text-center">Projects</h1>
    </b-container>
    <b-container fluid="xl" class="py-5">
      <section>
         <div class="row">
          <!-- <pre>{{ products }}</pre> -->
          <div class="col-md-3" v-for="product in products" :key="product.id">
            <router-link :to="product.view_url" class="card-link">
              <b-card
                :title="product.name"
                :img-src="product.default_product_image.image_url"
                :img-alt="product.name"
                img-top
                class="card-product mb-2"
              >
              <!-- <b-card-text>
                <span><Price Here></span>
              </b-card-text> -->
              </b-card>
            </router-link>
          </div>
        </div>
        <div class="overflow-auto">
          <b-pagination-nav :number-of-pages="10" align="center" use-router></b-pagination-nav>
        </div>
      </section>
    </b-container>
  </div>
</template>


<script>
// @ is an alias to /src
import ProductList from '@/components/ProductList.vue'
import NavCategory from '@/components/NavCategory.vue'

export default {
  name: 'Products',
  components: {
    NavCategory,
  },
  data () {
    return {
      products: {}
    }
  },
  created () {
      // console.log(this.section);
      this.$axios
          .get(this.$apiUrl+'/products?page_limit=20&token='+this.$apiToken)
          .then(response => {
            this.products = response.data.products;
            // console.log(this.pageData);
          }).catch(error => {
            console.log('There was an error:' + error.response)
          })
    }

}
</script>

<style scoped>

  .card-link {
    color: #212529;
  }

  .card-link:hover .card-title{
    text-decoration: underline;
  }

  .card-product {
    border-radius: 0px;
    border: none;
    margin-bottom: 2rem !important;
  }

  .card-product .card-img-top {
    border-radius: 0px;
    object-fit: cover;
    height: 300px; 
  }

  .card-product .card-body {
    padding: 15px 0px 15px 0px;
    height: 125px;
  }

  .card-product .card-title {
    margin-bottom: 0px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    font-size: 1.35em;
  }

  .card-product .card-text {

  }

</style>