<template>
  <b-breadcrumb :items="items" class="rounded-0">
    
  </b-breadcrumb>
</template>

<script>
  export default {
    props: {
      items: Array
    },
  }
</script>

<style scoped>
  .breadcrumb {
    background: none;
    margin-bottom: 0px;
    padding-left: 0px;
  }
</style>